<!--
 * @Description: 项目
 * @Author: luocheng
 * @Date: 2021-10-25 18:50:04
 * @LastEditors: luocheng
 * @LastEditTime: 2021-12-29 14:13:13
-->
<template>
	<div class="projects">
		<header class="filter" v-if="false">
			<!-- <a
				href="javascript:;"
				class="recent"
				@click="onFilter('recent')"
				:class="{
          'active': filterType === 'recent'
        }"
			>最近</a> -->
			<el-dropdown>
				<span class="el-dropdown-link">
					全部<i class="el-icon-arrow-down el-icon--right"></i>
				</span>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item>黄金糕</el-dropdown-item>
					<el-dropdown-item>狮子头</el-dropdown-item>
					<el-dropdown-item>螺蛳粉</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
		</header>
		<article class="project-list">
			<template v-if="projectForShow && projectForShow.length">
				<ArchiItem
				v-for="item in projectForShow"
				:key="item.id"
				:archiData="item"
				:type="'project'"
			></ArchiItem>
			</template>
      <el-empty description="暂无项目" v-else></el-empty>
		</article>
	</div>
</template>

<script>
import { Dropdown, DropdownItem, DropdownMenu, Empty } from 'element-ui';
import ArchiItem from './ArchiItem';

export default {
	name: 'Projects',
	components: {
		'el-dropdown': Dropdown,
		'el-dropdown-menu': DropdownMenu,
		'el-dropdown-item': DropdownItem,
		'el-empty': Empty,
		ArchiItem
	},
	props: {
		// 项目列表
		projects: {
			type: Array,
			default: () => [],
			required: true
		},
		// 侧边栏选中类型
		navKey: {
			type: [Number, String],
			default: 'organization',
			required: true
		}
	},
	data() {
		return {
			// 过滤项
			filterType: '',
			// 过滤列表
			filters: [
				{
					label: '全部',
					value: ''
				},
				{
					label: '其他',
					value: 'other'
				}
			],
		};
	},
	computed: {
		// 显示的项目列表
		projectForShow() {
			if (!this.navKey || this.navKey === 'projects') {
				return this.projects || [];
			}
			const result = [];
			this.projects.forEach(ele => {
				if (typeof ele.industry_type === 'string' && ele.industry_type.split(',').includes(this.navKey)) {
					result.push(ele);
        } else if (Array.isArray(ele.industry_type) && ele.industry_type.includes(this.navKey)) {
					result.push(ele);
        }
			})
			return result;
		}
	},
	methods: {
		/**
		 * @desc: 过滤
		 * @param {string} value 过滤值
		 */
		onFilter(value) {
			this.filterType = value;
		}
	}
};
</script>

<style lang="less" scoped>
.projects {
	height: 100%;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	width: calc(100% + 15px);
  margin-right: -15px;
	.filter {
		width: 100%;
		display: flex;
		// margin-bottom: 12px;
		.recent {
			box-sizing: border-box;
			line-height: 32px;
			font-size: 14px;
			font-family: PingFangSC, PingFangSC-Regular;
			font-weight: 400;
			color: #202126;
			padding-right: 20px;
			&.active {
				font-family: PingFangSC, PingFangSC-Medium;
				color: #0f62fe;
			}
		}
		.el-dropdown-link {
			display: block;
			cursor: pointer;
			color: #409eff;
			line-height: 32px;
			font-size: 14px;
			font-family: PingFangSC, PingFangSC-Regular;
			font-weight: 400;
			color: #202126;
		}
		.el-icon-arrow-down {
			font-size: 12px;
		}
	}
	.project-list {
		width: 100%;
		box-sizing: border-box;
		// padding-top: 12px;
		overflow: hidden;
		overflow-y: auto;
		display: flex;
		flex-wrap: wrap;
	}
	.el-empty{
    margin: 0 auto;;
  }
}
</style>