<!--
 * @Description: 平台入口内容区
 * @Author: luocheng
 * @Date: 2021-10-25 17:21:02
 * @LastEditors: luocheng
 * @LastEditTime: 2021-12-29 14:26:01
-->
<template>
	<div class="entry-content">
		<article class="content-box">
			<template v-if="archiData">
        <!-- 组织架构 -->
        <Organization v-if="navKey === 'organization'" :company="company"></Organization>
        <!-- 项目 -->
        <Projects v-else :projects="projects" :navKey="navKey"></Projects>
      </template>
      <el-empty description="暂无架构数据" v-else></el-empty>
		</article>
	</div>
</template>

<script>
import { Empty } from 'element-ui';
import Organization from './Organization';
import Projects from './Projects';

export default {
	name: 'EntryContent',
	components: {
    'el-empty': Empty,
		Organization,
		Projects
	},
	props: {
		// 侧边栏选中类型
		navKey: {
			type: [Number, String],
			default: 'organization',
			required: true
		},
    // 组织架构数据
    archiData: {
      type: [Object, null],
      required: true,
      default: () => {}
    }
	},
  data() {
    return {
      // 公司
      company: [],
      // 所有项目
      projects: [],
      // 南华封面图@陈忠
      projectImg: require('@/assets/images/test/project.jpeg'),
			companyImg: require('@/assets/images/test/compny.png'),
    }
  },
  created() {
    if (!this.archiData) return;
    const { company = [], project = []} = this.archiData;
    this.company = this.formatCover(company);
    this.projects = this.formatCover(project);

  },
  methods: {
    /**
     * @desc: 封面
     * @param {Array} arr 列表
     * @return {*}
     */
    formatCover(arr) {
      return arr.map(ele => {
        const { photos = '', type = '', id = '' } = ele;
        let cover = '';
        if (photos) {
          let photoList = JSON.parse(photos);
          if (Array.isArray(photoList) && photoList.length) {
            cover = photoList[0].path || '';
          }
        }
        if (!cover) {
          cover = type === 'company' || id.toString().indexOf('company') > -1 ? this.companyImg : this.projectImg;
        }
        return {
          ...ele,
          cover
        }
      })
    }
  }
};
</script>

<style lang="less" scoped>
.entry-content {
	flex: 1;
	height: 100%;
	overflow: hidden;
	flex-direction: column;
	box-sizing: border-box;
	padding: 20px 50px 20px 50px;
	background: rgb(244, 245, 247);
	.content-box {
		height: 100%;
		width: 100%;
		overflow: hidden;
	}
  .el-empty{
    margin: 0 auto;;
  }
}
</style>