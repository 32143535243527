<!--
 * @Description: 组织架构
 * @Author: luocheng
 * @Date: 2021-10-25 18:33:05
 * @LastEditors: luocheng
 * @LastEditTime: 2021-10-27 20:56:54
-->
<template>
  <div class="organization">
     <el-tabs v-model="activeOrg" v-if="false">
      <el-tab-pane label="集团 & 子集团" name="group"></el-tab-pane>
      <el-tab-pane label="公司" name="company"></el-tab-pane>
    </el-tabs>
    <article class="org-content">
      <!-- 集团 -->
      <section class="group" v-if="activeOrg === 'group'">
        <h4 class="title">集团</h4>
        <div class="archi-list">
          <ArchiItem v-for="item in 1" :key="item" :archiData="archiData"></ArchiItem>
        </div>
        <h4 class="title">子集团</h4>
        <div class="archi-list">
          <ArchiItem v-for="item in 5" :key="item" :archiData="archiData"></ArchiItem>
        </div>
      </section>
      <section class="group" v-else-if="activeOrg === 'company'">
        <!-- <h4 class="title">最近</h4>
        <div class="archi-list">
          <ArchiItem v-for="item in 6" :key="item" :archiData="archiData"></ArchiItem>
        </div> -->
        <div class="title" v-if="false">
          <el-dropdown class="dropdown-title">
          <span class="el-dropdown-link">
            全部<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>黄金糕</el-dropdown-item>
            <el-dropdown-item>狮子头</el-dropdown-item>
            <el-dropdown-item>螺蛳粉</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        </div>
        <div class="archi-list">
          <template v-if="companyForShow && companyForShow.length">

          <ArchiItem v-for="item in companyForShow" :key="item.id" :archiData="item" :type="'company'"></ArchiItem>
          </template>
      <el-empty description="暂无项目" v-else></el-empty>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
import { TabPane, Tabs, Dropdown, DropdownItem, DropdownMenu, Empty } from 'element-ui';
import ArchiItem from './ArchiItem';

export default {
  name: 'Organization',
  components: {
    'el-tabs': Tabs,
    'el-tab-pane': TabPane,
    'el-dropdown': Dropdown,
		'el-dropdown-menu': DropdownMenu,
		'el-dropdown-item': DropdownItem,
    'el-empty': Empty,
    ArchiItem
  },
  props: {
    // 公司信息
    company: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  data() {
    return {
      // 当前tab
      activeOrg: 'company',
      // 架构数据ITEM test
    }
  },
  computed: {
    // 用于显示的公司信息(带筛选)
    companyForShow() {
      return this.company;
    }
  }
}
</script>

<style lang="less" scoped>
.organization{
  height: 100%;
  width: calc(100% + 15px);
  display: flex;
  flex-direction: column;
  margin-right: -15px;
  :deep(.el-tabs) {
    .el-tabs__active-bar{
      display: none;
    }
    .el-tabs__nav-wrap::after{
     height: 0; 
    }
    .el-tabs__content{
      display: none;
    }
  }
  .org-content{
    width: 100%;
    flex: 1;
    overflow: hidden;
    overflow-y: auto;
    .title{
      width: 100%;
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #202126;
      line-height: 22px;
      padding-bottom: 12px;
    }
    .dropdown-title{
      .el-dropdown-link {
			display: block;
			cursor: pointer;
			color: #409eff;
			line-height: 32px;
			font-size: 14px;
			font-family: PingFangSC, PingFangSC-Regular;
			font-weight: 400;
			color: #202126;
		}
		.el-icon-arrow-down {
			font-size: 12px;
		}
    }
    .archi-list{
      display: flex;
      flex-wrap: wrap;
    }
  }
  .el-empty{
    margin: 0 auto;;
  }
}
</style>