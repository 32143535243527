<!--
 * @Description: 平台入口左侧
 * @Author: luocheng
 * @Date: 2021-10-25 16:57:36
 * @LastEditors: luocheng
 * @LastEditTime: 2022-06-13 16:59:10
-->
<template>
  <div class="left-nav">
    <ul class="nav-list">
      <li class="nav-item" v-for="(item, index) in navList" :key="index"
        :class="{
          'active': activeNav === item.value
        }"
        @click="onNav(item)"
      >
        <i class="iconfont icon-left" :class="item.icon"
          :style="{
            color: item.icon === 'iconzuzhijiagou1' ? '#1192e8' : '#a56eff'
          }"
        ></i>
        <h4 class="lable" :class="{
          'secondary': item.type === 'secondary'
        }">{{ item.label }} ({{ item.count || 0 }})</h4>
        <i class="icon-mark" :class="{
          'el-icon-warning': item.markType
        }"></i>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'LeftNaV',
  props: {
    value: {
      type: [Number, String],
      default: 'organization',
      required: true
    },
    // 项目类型
    projectType: {
      type: Array,
      default: () => [],
      required: true
    },
    // 组织架构数据
    archiData: {
      type: [Object, null],
      required: true,
      default: () => {}
    }
  },
  data() {
    return {
      // 当前菜单
      activeNav: 'organization',
      // 侧边栏列表
      navList: [
        // {
        //   value: 'organization',
        //   type: 'main', // 主要
        //   icon: 'iconzuzhijiagou1', // 左侧icon 如果为空则不显示
        //   label: '组织架构', // label
        //   mark: false, // 是否存在标记
        //   markType: '', // 可能有多重标记类型
        //   count: 0
        // },
        // {
        //   value: 'projects',
        //   type: 'main', // 主要
        //   icon: 'iconxiangmujihe', // 左侧icon 如果为空则不显示
        //   label: '项目集合', // label
        //   mark: false, // 是否存在标记
        //   markType: '', // 可能有多重标记类型
        //   count: 0
        // }
      ]
    }
  },
  created() {
    this.activeNav = this.value || 1;
    const { project = [], company = [] } = this.archiData;
    this.navList = [
      {
        value: 'organization',
        type: 'main', // 主要
        icon: 'iconzuzhijiagou1', // 左侧icon 如果为空则不显示
        label: '组织架构', // label
        mark: false, // 是否存在标记
        markType: '', // 可能有多重标记类型
        count: company.length || 0
      },
      {
        value: 'projects',
        type: 'main', // 主要
        icon: 'iconxiangmujihe', // 左侧icon 如果为空则不显示
        label: '项目集合', // label
        mark: false, // 是否存在标记
        markType: '', // 可能有多重标记类型
        count: project.length || 0
      }
    ];
    if (+process.env.VUE_APP_IS_PROJECT_LEVEL) {
      // 项目级平台
      this.navList.shift();
    }
    this.projectType.forEach(ele => {
      this.navList.push({
         value: ele.code,
        type: 'secondary', // 次要
        icon: '', // 左侧icon 如果为空则不显示
        label: ele.name, // label
        mark: false, // 是否存在标记
        markType: '', // 可能有多重标记类型
        count: this.getProjectCount(project, ele.code)
      })
    });
  },
  methods: {
    /**
     * @desc: 点击菜单
     * @param {Object} nav 菜单对象
     */
    onNav(nav) {
      this.activeNav = nav.value;
      this.$emit('input', nav.value);
    },
    /**
     * @desc: 获取项目类型数量
     * @param {Array} projects 项目列表
     * @param {String} code 项目类型
     * @return {Number} 数量
     */
    getProjectCount(projects, code) {
      let count = 0;
      projects.forEach(ele => {
        // 属于多类型项目
        if (typeof ele.industry_type === 'string' && ele.industry_type.split(',').includes(code)) {
          count++;
        } else if (Array.isArray(ele.industry_type) && ele.industry_type.includes(code)) {
          count++;
        }
      });
      return count || 0;
    }
  }
}
</script>

<style lang="less" scoped>
@width: 240px;
@itemHeight: 40px;
.left-nav{
  width: @width;
  height: 100%;
  box-sizing: border-box;
  border-right: 1px solid #e5e5e5;
  background: #f2f3f5;
  overflow: hidden;
  overflow-y: auto;
  .nav-list{
    box-sizing: border-box;
    width: 100%;
    min-height: 100%;
    padding: 33px 0;
    .nav-item{
      height: @itemHeight;
      box-sizing: border-box;
      padding: 9px 15px 9px 18px;
      background: transparent;
      display: flex;
      cursor: pointer;
      &.active{
        background: #fff;
      }
      .icon-left{
        width: 20px;
        margin-right: 8px;
        line-height: 22px;
        font-size: 16px;
        color: @theme;
      }
      .lable{
        flex: 1;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        text-align: left;
        color: #161616;
        line-height: 22px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &.secondary{
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
        }
      }
      .icon-mark{
        line-height: 22px;
        font-size: 16px;
        margin-left: 8px;
        &.el-icon-warning{
          color: #f1c21b;
        }
      }
    }
  }
}
</style>