<!--
 * @Description: 第二屏
 * @Author: luocheng
 * @Date: 2021-10-25 16:14:29
 * @LastEditors: luocheng
 * @LastEditTime: 2021-10-25 16:59:47
-->
<template>
  <div class="second-screen">
    <SecondHeader v-model="activeTab"></SecondHeader>
    <article class="content">
      <!-- 平台入口 -->
      <Entrance v-if="+activeTab === 1"></Entrance>
    </article>
  </div>
</template>

<script>
import SecondHeader from './components/SecondHeader';
import Entrance from './components/entrance/Index';

export default {
  name: 'SecondScreen',
  components: {
    SecondHeader,
    Entrance
  },
  data() {
    return {
      // 顶部Tab 1 平台入口 应用中心 项企看板 远程工地
      activeTab: 1
    }
  }
}
</script>

<style lang="less" scoped>
.second-screen{
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  min-width: 860px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  .content{
    flex: 1;
    width: 100%;
    overflow: hidden;
  }
}
</style>