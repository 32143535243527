<!--
 * @Description: 平台入口
 * @Author: luocheng
 * @Date: 2021-10-25 16:55:42
 * @LastEditors: luocheng
 * @LastEditTime: 2022-06-13 16:59:33
-->
<template>
	<div class="entrance" v-if="inited">
		<LeftNav
			v-model="navKey"
			:projectType="projectType"
			:archiData="archiData"
		></LeftNav>
		<EntryContent
			:navKey="navKey"
			:archiData="archiData"
			v-if="archiData"
		></EntryContent>
	</div>
</template>

<script>
import LeftNav from './LeftNav';
import EntryContent from './EntryContent';
import { dataInterface } from '@/apis/data/index';

export default {
	name: 'Entrance',
	components: {
		LeftNav,
		EntryContent
	},
	data() {
		return {
			// 菜单key
			navKey: 'organization',
			projectType: [],
			// 组织架构数据
			archiData: null,
      inited: false
		};
	},
	created() {
		this.getArchi();
		this.navKey = +process.env.VUE_APP_IS_PROJECT_LEVEL ? 'projects' : 'organization'
	},
	methods: {
		/**
		 * @desc: 获取可用组织架构
		 */
		getArchi() {
			this.$loading();
			dataInterface({
				__method_name__: 'globalFunctionCall',
				typeName: 'Archi',
				type: 'value',
				funcName: 'AllArchi'
			})
				.then((res) => {
					if (!res || res.status !== 200) return false;
					this.archiData = res.data.data;
					const { company = [], project = [], project_type = [] } = res.data.data;
          localStorage.setItem('myCompany', JSON.stringify(company));
          localStorage.setItem('myProjects', JSON.stringify(project));
					this.projectType = project_type;
          this.inited = true;
					this.$loading().close();
				})
				.catch((err) => {
					console.log(err);
					this.$loading().close();
				});
		},
		/**
		 * @desc: 获取项目类型列表
		 * @param {Array} arr
		 */
		getPeojectTypes(arr) {
			if (!arr || !Array.isArray(arr) || !arr.length) return [];
			const result = [];
			arr.forEach((ele) => {
				console.log(ele, '0000');
				if (!result.includes(ele.project_type)) {
					result.push(ele.project_type);
				}
			});
			return result;
		}
	}
};
</script>

<style lang="less" scoped>
.entrance {
	height: 100%;
	width: 100%;
	overflow: hidden;
	display: flex;
}
</style>