<!--
 * @Description: 第二屏Header
 * @Author: luocheng
 * @Date: 2021-10-25 16:16:40
 * @LastEditors: luocheng
 * @LastEditTime: 2022-06-14 15:05:36
-->
<template>
	<div class="second-header">
		<section class="name">
			<!-- <img src="@/assets/images/nanhua_logo.png" alt="logo" class="logo"> -->
			<img
				:src="projectObj[projectEnvironment].logo"
				:alt="projectObj[projectEnvironment].fullName"
				class="logo"
				:class="{
					'is-nanhua': projectEnvironment === 'nh'
				}"
			/>
			<h3 class="label">{{ projectObj[projectEnvironment].fullName }}</h3>
		</section>
		<!-- <ul class="tabs">
			<li class="tab" v-for="item in tabList" :key="item.value"
				:class="{
					'active': item.value === activeTable
				}"
				@click="onTab(item)"
			>
				{{ item.label }}
			</li>
		</ul> -->
		<section class="info">
			<!-- <img src="@/assets/images/second-screen/notice.png" alt="notice" class="icon notice"> -->
			<!-- <i class="icon el-icon-bell"></i> -->
			<el-dropdown>
				<i class="userinfo icon el-icon-user"></i>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item class="quit-item" @click.native="onQuit">退出</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
		</section>
	</div>
</template>

<script>
import { Dropdown, DropdownItem, DropdownMenu } from 'element-ui';
import { setToken } from '@/utils/tools';
import eventBus from '@/plugins/eventBus';
import { mapState } from 'vuex';
import { projectObj } from '@/config/projectConfig';

export default {
	name: 'SecondHeader',
	props: {
		value: {
			type: Number,
			default: 1,
			required: true
		}
	},
	components: {
		'el-dropdown': Dropdown,
		'el-dropdown-menu': DropdownMenu,
		'el-dropdown-item': DropdownItem
	},
	data() {
		return {
			// tab列表
			tabList: [
				{
					label: '平台入口',
					value: 1
				},
				{
					label: '应用中心',
					value: 2
				},
				{
					label: '项企看板',
					value: 3
				},
				{
					label: '远程工地',
					value: 4
				},
			],
			activeTable: 1,
			projectObj
		}
	},
	computed: {
		...mapState(['projectEnvironment'])
	},
	created() {
		this.activeTable = this.value || 1;
	},
	methods: {
		/**
		 * @desc: 点击Tab
		 * @param {Object} tab对象
		 */
		onTab(tab) {
			this.$emit('input', tab.value);
			this.activeTable = tab.value;
		},
		/**
		 * @desc: 退出
		 */
		onQuit() {
			// 移除路由文件重新获取一次
			setToken('');
			sessionStorage.clear();
			localStorage.clear();
			this.$router.replace({
				name: 'Login'
			});
			// sessionStorage.removeItem('pageData');
			eventBus.$emit('updateRoute');
		}
	}
};
</script>

<style lang="less" scoped>
@height: 48px;
.second-header {
	height: @height;
	box-sizing: border-box;
	padding: 0 16px;
	display: flex;
	justify-content: space-between;
  background: linear-gradient(237deg,#3aaef2, #4098ef 25%, #2e6fe4 71%, #1e4cda 94%);
	.name {
		display: flex;
    min-width: 240px;
		height: @height;
		box-sizing: border-box;
		padding: 8px 0;
		&.is-nanhua{
			// background: #fff;
		}
		img {
			height: 32px;
			display: block;
			height: 32px;
			min-width: 32px;
			object-fit: cover;
			border-radius: 6px;
		}
		.label {
			margin-left: 16px;
			font-size: 16px;
			font-family: PingFangSC, PingFangSC-Medium;
			font-weight: 500;
			color: #ffffff;
			line-height: 32px;
		}
	}
	.tabs{
		display: flex;
		height: @height;
		.tab{
			min-width: 112px;
			height: @height;
			box-sizing: border-box;
			padding: 8px 24px;
			text-align: center;
			opacity: 0.5;
			font-size: 16px;
			font-family: PingFangSC, PingFangSC-Medium;
			font-weight: 500;
			color: #ffffff;
			opacity: .5;
			line-height: 24px;
			background: transparent;
			cursor: pointer;
			&:hover{
				color: #ffffff;
				background: rgb(77, 134, 232);
				opacity: 1;
			}
			&.active{
				color: #ffffff;
				// background: rgb(77, 134, 232);
				opacity: 1;
				background: rgba(255, 255, 255, .2);
			}
		}
	}
	.info{
		display: flex;
		height: @height;
		box-sizing: border-box;
		padding: 8px 0;
		.icon{
			height: 20px;
			width: 20px;
			line-height: 32px;
			color: #ffffff;
			font-size: 20px;
			cursor: pointer;
			&.notice{
				margin-top: 6px;
			}
		}
		.icon+.icon{
			margin-left: 24px;
		}
	}
}
</style>
<style>
.el-dropdown-menu__item.quit-item{
	color: #F56C6C;
	padding: 0 20px;
	font-weight: bold;
}
</style>