var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left-nav"},[_c('ul',{staticClass:"nav-list"},_vm._l((_vm.navList),function(item,index){return _c('li',{key:index,staticClass:"nav-item",class:{
        'active': _vm.activeNav === item.value
      },on:{"click":function($event){return _vm.onNav(item)}}},[_c('i',{staticClass:"iconfont icon-left",class:item.icon,style:({
          color: item.icon === 'iconzuzhijiagou1' ? '#1192e8' : '#a56eff'
        })}),_c('h4',{staticClass:"lable",class:{
        'secondary': item.type === 'secondary'
      }},[_vm._v(_vm._s(item.label)+" ("+_vm._s(item.count || 0)+")")]),_c('i',{staticClass:"icon-mark",class:{
        'el-icon-warning': item.markType
      }})])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }