<!--
 * @Description: 项目ITEM
 * @Author: luocheng
 * @Date: 2021-10-25 17:39:58
 * @LastEditors: Shiltin 18580045074@163.com
 * @LastEditTime: 2022-11-01 14:14:04
-->
<template>
	<div
		class="archi-item"
		v-if="archiData"
		@click="onArchi"
	>
		<section class="info">
			<!-- <img
				:src="archiData.cover"
				alt=""
				class="cover"
			> -->
			<el-image
				style="width: 100%; height: 100%"
				:src="archiData.cover"
				class="cover"
				lazy
				:fit="'fill'">
			</el-image>
			<!-- 具体判断条件需要再考虑 -->
			<div class="accident-info" v-if="archiData.type === 3 && archiData.status">
				<p>{{ archiData.accidentDesc || '暂无备注' }}</p>
			</div>
		</section>
		<section
			class="other"
			:class="{
				'warning': archiData.type === 3 && archiData.status
			}"
		>
			<h5 class="title">{{ archiData.name || '--' }}</h5>
			<!-- 暂时无状态 -->
			<div class="status" v-if="false">
				<span
					class="status-tag"
					:class="archiData.type === 3 && archiData.status"
				>
					{{ archiData.status ? '异常' : '在建' }}
				</span>
				<i class="icon el-icon-edit"></i>
			</div>
		</section>
	</div>
</template>

<script>
import { dataInterface } from '@/apis/data/index';
import { mapState } from 'vuex';
import { Image } from 'element-ui';

export default {
	name: 'ArchiItem',
	components: {
		'el-image': Image
	},
	props: {
		// 数据
		archiData: {
			type: Object,
			required: true,
			default: () => {}
		},
		// 类型 公司或项目
		type: {
			type: String,
			default: '',
			required: true
		}
	},
	data() {
		return {
			// projectImg: require('@/assets/images/test/project.jpeg'),
			// companyImg: require('@/assets/images/test/compny.png'),
			childrenUUID: '72da4bd8-c508-48c9-8e75-5ed59e6b09d2',
			urlUUID: '7c5166f1-84aa-4639-83f6-7a2a07a518d9'
		};
	},
	created() {
		// console.log(this.archiData, 'archiData')
	},
	computed: {
		...mapState['globalConfig']
	},
	methods: {
		/**
		 * @desc: 切换组织架构
		 */
		onArchi() {
			if (this.archiData.id === this.$GetTargetArchi('id')) {
				// this.$message.warning('当前架构!');
				// return false;
			}
			this.$loading();
			dataInterface({
				__method_name__: 'globalFunctionCall',
				typeName: 'LoginFuncClass',
				type: 'behavior',
				funcName: 'ToggleArchBehavior',
				payload: {
					archi_id: this.archiData.id,
					archi_table: this.type === 'company' ? 'org_companys' : 'org_projects'
				}
			}).then((res) => {
				if (!res || res.status !== 200) {
					return false;
				}
				//模型上传需要审批的项目地址
				let routerApprovalModel = ['https://bim.xwjgpt.com/']
				this.$store.commit('setTargetArchi', {
					archiType: this.type,
					// sectionId: '38', // 部门ID
					// sectionName: '领导班子', // 部门名称
					archiId: res.data.data.archi_id,
					...res.data.data,
					id: res.data.data.data_id,
					modelApproval: !!routerApprovalModel.includes(process.env.VUE_APP_V3_HOST),
					company_id: res.data.data.data.company_id || ''
				});
				const routeObj = JSON.parse(sessionStorage.getItem('indexPath'));
				localStorage.setItem('userRoles', res.data.data.permissions || '');
				const pathObj = this.getPathObj(
					routeObj ? routeObj.path : process.env.VUE_APP_HOME_PATH
				);
				this.$store.commit('setNavHistory', [pathObj]);
				this.$router.push(routeObj);
				this.$loading().close();
			}).catch((err) => {
				console.log(err);
				this.$loading().close();
			});
		},
		/**
		 * @desc: 获取路由对象
		 */
		getPathObj(path) {
			let result = null;
			const globalConfig = this.$store.state.globalConfig || [];
			for (let i = 0; i < globalConfig.length; i++) {
				if (globalConfig[i][this.urlUUID] === path) {
					result = globalConfig[i];
					break;
				}
				if (globalConfig[i][this.childrenUUID]) {
					const children = globalConfig[i][this.childrenUUID];
					for (let j = 0; j < children.length; j++) {
						if (children[j][this.urlUUID] === path) {
							result = children[j];
							break;
						}
						if (children[j][this.childrenUUID]) {
							const childrenChilds = children[j][this.childrenUUID];
							for (let k = 0; k < childrenChilds.length; k++) {
								if (childrenChilds[k][this.urlUUID] === path) {
									result = childrenChilds[k];
									break;
								}
							}
						}
					}
				}
			}
			return result;
		}
	}
};
</script>

<style lang="less" scoped>
@height: 224px;
@width: 224px;
.archi-item {
	min-height: @height;
	width: @width;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	margin-right: 32px;
	margin-bottom: 32px;
	&:hover {
		box-shadow: 8px 8px 20px 5px rgba(72, 134, 255, 0.1);
		cursor: pointer;
	}
	.info {
		position: relative;
		height: 126px;
		width: 100%;
		background: #d0e2ff;
		overflow: hidden;
		.cover {
			display: block;
			height: 100%;
			width: 100%;
			object-fit: cover;
		}
		.accident-info {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 10;
			height: 100%;
			width: 100%;
			box-sizing: border-box;
			padding: 12px 16px;
			background: #f1c21b;
			p {
				height: 100%;
				width: 100%;
				font-size: 12px;
				font-family: PingFangSC, PingFangSC-Regular;
				font-weight: 400;
				text-align: justify;
				color: #202126;
				line-height: 20px;
				letter-spacing: 1px;
				overflow: hidden;
				display: -webkit-box;
				text-overflow: ellipsis;
				-webkit-line-clamp: 5; /*要显示的行数*/
				-webkit-box-orient: vertical;
			}
		}
	}
	.other {
		// height: 96px;
		flex: 1;
		width: 100%;
		overflow: hidden;
		background: #fff;
		display: flex;
		flex-direction: column;
		box-sizing: border-box;
		padding: 12px 16px;
		&.warning {
			background: rgb(240, 213, 120);
		}
		.title {
			flex: 1;
			width: 100%;
			font-size: 14px;
			font-family: PingFangSC, PingFangSC-Medium;
			font-weight: 500;
			text-align: left;
			color: #202126;
			line-height: 22px;
			letter-spacing: 1px;
			word-break: break-all;
			overflow: hidden;
		}
		.status {
			margin-top: 10px;
			width: 100%;
			display: flex;
			.status-tag {
				box-sizing: border-box;
				line-height: 20px;
				font-family: PingFangSC, PingFangSC-Regular;
				font-weight: 400;
				text-align: left;
				color: #ffffff;
				line-height: 20px;
				padding: 2px 5px;
				text-align: center;
				background: #0f62fe;
				border-radius: 4px;
				font-size: 12px;
				&.warning {
					color: #202126;
					background: #f1c21b;
				}
			}
		}
		.el-icon-edit {
			margin-left: 8px;
			height: 24px;
			line-height: 24px;
			font-size: 12px;
			color: #0f62fe;
		}
	}
}
</style>